import { Icon24InfoStarFilled } from '@care/react-icons';
import { theme } from '@care/material-ui-theme';
import { Box, Link, Typography } from '@mui/material';
import { SOCIAL_MEDIA_REVIEWS } from '@/constants';

const Reviews = () => {
  const renderReview = (provider: 'google' | 'facebook') => {
    const review = SOCIAL_MEDIA_REVIEWS[provider];
    const { label, rating, url } = review;

    return (
      <Box
        sx={{
          flex: '0 0 280px',
          display: 'flex',
          justifyContent: 'unset',
          paddingBottom: '15px',
          width: 'auto',
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
            paddingBottom: '0px',
            width: '330px',
          },
        }}
        component="span">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            paddingRight: '10px',
          }}
          component="span">
          <Box
            sx={{
              display: 'inline-flex',
              verticalAlign: 'sub',
            }}
            component="span">
            <Icon24InfoStarFilled />
          </Box>
        </Box>
        <Typography
          variant="display2"
          sx={{
            alignItems: 'center',
            color: theme.palette.care?.white,
            display: 'flex',
            margin: theme.spacing(0, 1),
          }}>
          {rating}
        </Typography>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '10px',
          }}>
          <Typography variant="body1" color="white">
            {label}
          </Typography>
          <Box
            sx={{
              textAlign: 'left',
              width: '100%',
            }}>
            <Link
              sx={{
                color: theme.palette.care?.yellow[500],
                fontSize: '16px',
                lineHeight: '26px',
                textDecoration: 'underline',
                '&:hover': {
                  color: theme.palette.care?.yellow[500],
                },
              }}
              data-testid={`read-${provider}-reviews-link`}
              href={url}>
              Read more reviews
            </Link>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        borderRadius: '0 0 24px 24px',
        backgroundColor: theme.palette.care?.navy[900],
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(6),
        },
      }}>
      {renderReview('google')}
      {renderReview('facebook')}
    </Box>
  );
};

export default Reviews;
