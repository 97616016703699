import { Button, Grid, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Image from 'next/image';
import { FC } from 'react';
import HeroImage from '@/assets/images/partner-hero.png';
import { useMobileQuery } from '@/utils/hooks';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES } from '@/constants';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import { useUserAuth } from '@/components/UserProvider';
import { AccountStatus } from '@/API/member';
import CTAButton from '../../shared/CTAButton';

interface Props {
  partnerName?: string;
  marketingPath?: string;
}

const Hero: FC<Props> = ({ partnerName, marketingPath }) => {
  const isMobile = useMobileQuery();
  const { auth } = useUserAuth();

  const handleButtonClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners page',
        cta_text: 'Get started',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: {
        ...PAGE_PROPERTIES.partners,
        partnerName,
        marketingPath,
      },
    });
  };

  return (
    <Grid container>
      <Grid
        alignItems="center"
        sx={{
          marginTop: theme.spacing(5),
        }}
        container
        justifyContent="center">
        <Grid
          alignItems="center"
          sx={{
            background: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.15)',
            gap: theme.spacing(2),
            marginRight: '-10%',
            padding: theme.spacing(4),
            zIndex: 1,

            [theme.breakpoints.up('xl')]: {
              gap: theme.spacing(3),
            },

            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(2),
              margin: theme.spacing(0, 'auto'),
              width: '90%',
            },
          }}
          container
          direction="column"
          item
          md={5}>
          <Typography align="center" variant={isMobile ? 'serifHeadline1' : 'serifDisplay3'}>
            {partnerName} clients, welcome to HomePay!
          </Typography>
          <Typography align="center" variant="body1" fontSize="20px">
            We’re a comprehensive tax and payroll service for busy home employers.
          </Typography>
          {(auth?.member?.homepayAccountStatus as AccountStatus) === AccountStatus.None ? (
            <Button
              fullWidth
              color="primary"
              href={getEnrollmentUrl(undefined, undefined, marketingPath)}
              onClick={handleButtonClick}
              size={isMobile ? 'medium' : 'large'}
              variant="contained">
              Get started
            </Button>
          ) : (
            <CTAButton
              color="primary"
              pageProperties={PAGE_PROPERTIES.partners}
              size={isMobile ? 'medium' : 'large'}
              variant="contained"
              partnerName={partnerName}
              marketingPath={marketingPath}
            />
          )}
        </Grid>
        {!isMobile && (
          <Grid item md={8}>
            <Image
              objectFit="contain"
              quality={100}
              layout="responsive"
              src={HeroImage}
              width={686}
              height={444}
              alt="partner-hero"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Hero;
