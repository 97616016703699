import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import getConfig from 'next/config';
import Image from 'next/image';
import HeroImage from '@/assets/images/partner-hero.png';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES } from '@/constants';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const { CORE_BASE_URL, FOR_PARTNERS_URL } = publicRuntimeConfig;

const HeroNoPartner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignUpClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners page',
        cta_text: 'Get started',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: { ...PAGE_PROPERTIES.partners, partnerName: 'No partner' },
    });
  };

  const handlePartnershipClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners page',
        cta_text: 'Partner with us',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: { ...PAGE_PROPERTIES.partners, partnerName: 'No partner' },
    });
  };

  return (
    <Grid container>
      <Grid
        alignItems="center"
        sx={{ marginTop: theme.spacing(5) }}
        container
        justifyContent="center">
        <Grid
          alignItems="center"
          sx={{
            background: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.15)',
            gap: theme.spacing(2),
            marginRight: '-10%',
            padding: theme.spacing(4),
            zIndex: 1,

            [theme.breakpoints.up('xl')]: {
              gap: theme.spacing(3),
            },

            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(2),
              margin: theme.spacing(0, 'auto'),
              width: '90%',
            },
          }}
          container
          direction="column"
          item
          md={6}>
          <Typography align="center" variant={isMobile ? 'serifHeadline1' : 'serifDisplay3'}>
            Welcome to HomePay!
          </Typography>
          <Typography variant="body1" fontSize="20px">
            Comprehensive tax and payroll services for busy home employers since 1992.
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: '90px',
              },
            }}>
            <Button
              fullWidth={isMobile}
              color="primary"
              href={`${CORE_BASE_URL}/app/hp-enrollment/signup`}
              onClick={handleSignUpClick}
              size={isMobile ? 'medium' : 'large'}
              variant="contained">
              Get started
            </Button>
            <Button
              fullWidth={isMobile}
              color="secondary"
              href={FOR_PARTNERS_URL}
              onClick={handlePartnershipClick}
              size={isMobile ? 'medium' : 'large'}
              variant="contained">
              Partner with us
            </Button>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item md={7}>
            <Image
              objectFit="contain"
              quality={100}
              layout="responsive"
              src={HeroImage}
              width={686}
              height={444}
              alt="partner-hero"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default HeroNoPartner;
