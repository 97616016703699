import { FC } from 'react';
import { theme } from '@care/material-ui-theme';
import { Box, Grid, Button, Typography } from '@mui/material';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { PAGE_PROPERTIES } from '@/constants';
import { useUserAuth } from '@/components/UserProvider';
import { AccountStatus } from '@/API/member';
import CTAButton from '../../shared/CTAButton';

interface Props {
  defaultPrice?: number;
  partnerName?: string;
  marketingPath?: string;
}

const PricingBanner: FC<Props> = ({ defaultPrice, partnerName, marketingPath }) => {
  const { auth } = useUserAuth();

  const partnerPricingText =
    partnerName && defaultPrice !== 75 ? (
      <Typography
        variant="body1"
        sx={{
          paddingTop: theme.spacing(1),
        }}>
        {partnerName} Exclusive discount: <strong>${defaultPrice} per month</strong>
      </Typography>
    ) : (
      <Typography
        variant="body1"
        sx={{
          paddingTop: theme.spacing(1),
        }}>
        {partnerName} Exclusive discount: First month free! (<strong>${defaultPrice} value</strong>)
      </Typography>
    );

  const handleButtonClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners page',
        cta_text: 'Get my discount',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: { ...PAGE_PROPERTIES.partners, partnerName, marketingPath },
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.care.red[100],
            padding: theme.spacing(2),
            justifyContent: 'space-around',
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
            flexWrap: 'wrap',
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(4),
            },
          }}>
          {partnerPricingText}
          {(auth?.member?.homepayAccountStatus as AccountStatus) === AccountStatus.None ? (
            <Button
              variant="contained"
              color="primary"
              sx={{
                padding: 0,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  flex: '0 0 auto',
                },
              }}
              href={getEnrollmentUrl(undefined, undefined, marketingPath)}
              onClick={handleButtonClick}>
              Get my discount
            </Button>
          ) : (
            <CTAButton
              sx={{
                padding: 0,
                width: '190px',
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  flex: '0 0 auto',
                },
              }}
              color="primary"
              pageProperties={PAGE_PROPERTIES.partners}
              variant="contained"
              partnerName={partnerName}
              marketingPath={marketingPath}
            />
          )}
        </Box>
      </Grid>
    </>
  );
};

export default PricingBanner;
