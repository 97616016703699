import { Grid, Box, GridSize } from '@mui/material';

interface StepProps {
  color: string;
  children: React.ReactNode;
  height?: string | number;
  md?: GridSize;
  xs?: GridSize;
}

const Step = ({ color, children, height, md = 4, xs = 12 }: StepProps) => (
  <Grid item xs={xs} md={md}>
    <Box borderRadius="16px" bgcolor={color} height={height} py={4} px={2} textAlign="center">
      {children}
    </Box>
  </Grid>
);

export default Step;
