import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import Layout from '@/components/Layout';
import { META_PROPERTIES, PAGE_PROPERTIES, PARTNER_NO_BILLING_PLANS } from '@/constants';
import usePartnerInfo from '@/utils/usePartnerInfo';
import { PRICING_PLAN, PricingPlanInput, PricingPlanResult } from '@/API/pricing';
import { LoadingSpinner } from '@/components/ui';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { Header } from '../Layout';
import Hero from './Hero';
import PartnerTaskCards from './Tasks';
import Description from './Description';
import PricingBanner from './PricingBanner';
import ServiceModal from './ServiceModal';
import HeroNoPartner from './HeroNoPartner';

const Partner = () => {
  const partnerInfo = usePartnerInfo();
  const isNoBilling = Boolean(
    partnerInfo?.pricingPlan && PARTNER_NO_BILLING_PLANS.includes(partnerInfo?.pricingPlan)
  );
  const [fetchPricingInfo, { data, loading }] = useLazyQuery<PricingPlanResult, PricingPlanInput>(
    PRICING_PLAN
  );
  const router = useRouter();

  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => setIsVisible(false);
  const handleOpen = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Partners',
        cta_text: 'View services and pricing',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: {
        ...PAGE_PROPERTIES.partners,
        partnerName: partnerInfo?.name,
        marketingPath: router.query.marketingPath?.toString(),
      },
    });

    setIsVisible(true);
  };

  const defaultPrice = data?.pricingPlan.pricingInformation?.filter(
    (plane) => plane.isDefault === true
  );

  useEffect(() => {
    if (partnerInfo?.pricingPlan) {
      fetchPricingInfo({
        variables: {
          input: {
            pricingPlan: partnerInfo?.pricingPlan,
          },
        },
      });
    }
  }, [partnerInfo, fetchPricingInfo]);

  useEffect(() => {
    if (partnerInfo?.name) {
      logAnalyticsEvent({
        name: AmplitudeEventName.screenViewed,
        pageProperties: {
          ...PAGE_PROPERTIES.partners,
          partnerName: partnerInfo?.name,
          marketingPath: router.query.marketingPath?.toString(),
        },
      });
    }
    if (!Object.prototype.hasOwnProperty.call(router.query, 'marketingPath')) {
      logAnalyticsEvent({
        name: AmplitudeEventName.screenViewed,
        pageProperties: PAGE_PROPERTIES.partners,
      });
    }
  }, [partnerInfo?.name, router.query]);

  return (
    <Layout
      HeaderComponent={Header}
      meta={META_PROPERTIES.partners}
      pageProperties={{
        ...PAGE_PROPERTIES.partners,
        partnerName: partnerInfo?.name,
        marketingPath: router.query.marketingPath?.toString(),
      }}
      skipScreenViewed>
      {partnerInfo || !Object.prototype.hasOwnProperty.call(router.query, 'marketingPath') ? (
        <Box maxWidth={1100} pb={4} mx="auto">
          <Grid gap={6} container>
            <Grid item xs={12}>
              {partnerInfo?.name ? (
                <Hero
                  partnerName={partnerInfo?.name}
                  marketingPath={router.query.marketingPath?.toString()}
                />
              ) : (
                <HeroNoPartner />
              )}
            </Grid>
            {partnerInfo?.name && !isNoBilling && (
              <Grid item xs={12}>
                <PricingBanner
                  defaultPrice={defaultPrice?.[0].amount}
                  partnerName={partnerInfo?.name}
                  marketingPath={router.query.marketingPath?.toString()}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              id="service_pricing"
              sx={{
                scrollMarginTop: '120px',
              }}>
              <PartnerTaskCards />
              <Box my={3} textAlign="center">
                {loading ? (
                  <LoadingSpinner data-testId="loading-spinner" inline />
                ) : (
                  <Button
                    sx={{
                      width: 275,
                    }}
                    color="secondary"
                    onClick={handleOpen}
                    variant="contained">
                    View services and pricing
                  </Button>
                )}
              </Box>
            </Grid>
            <Description />
          </Grid>
        </Box>
      ) : (
        <LoadingSpinner data-testId="loading-spinner" />
      )}
      <ServiceModal
        defaultPrice={defaultPrice?.[0].amount}
        isVisible={isVisible}
        title="Services & Pricing"
        onClose={handleClose}
        partnerName={partnerInfo?.name}
        marketingPath={router.query.marketingPath?.toString()}
        isNoBilling={isNoBilling}
      />
    </Layout>
  );
};

export default Partner;
