import gql from 'graphql-tag';

export interface PricingPlanInput {
  input: {
    pricingPlan: string;
  };
}

export interface PricingInformation {
  amount: number;
  isDefault: boolean;
}

export interface PricingPlanResult {
  pricingPlan: {
    pricingInformation: PricingInformation[];
  };
}

export const PRICING_PLAN = gql`
  query pricingPlan($input: PricingPlanInput!) {
    pricingPlan(input: $input) {
      pricingInformation {
        billingFeeGroup
        amount
        isDefault
      }
    }
  }
`;
