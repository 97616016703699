import { Box, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { Carousel } from '@/components/ui';
import { PARTNER_PAGE_TESTIMONIALS } from '@/constants';
import RatingStars from '../../../../assets/images/rating-stars.svg';
import Reviews from './Reviews';

const Testimonials = () => {
  return (
    <>
      <Box
        sx={{
          borderRadius: theme.spacing(3, 3, 0, 0),
          backgroundColor: theme.palette.care?.grey[100],
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(5, 5, 0, 5),
          },
        }}>
        <Box
          sx={{
            color: theme.palette.care?.navy[900],
            height: 285,
            margin: '0 auto',
            padding: theme.spacing(2),
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
              height: '235px',
              padding: '0 30px',
              width: '85%',
            },
            [theme.breakpoints.up('md')]: {
              width: '600px',
            },
          }}>
          <Box
            sx={{
              margin: '0 auto',
              top: '50%',
              transform: 'translateY(-50%)',
              position: 'relative',
              height: '100%',
            }}>
            <Carousel>
              {PARTNER_PAGE_TESTIMONIALS.map((review) => (
                <Box data-testid={`review-slide-${review.id}`} key={review.id}>
                  <Typography variant="serifHeadline2" fontSize={[24, 28]}>
                    {review.impression}
                  </Typography>
                  <Typography variant="body1" marginY={2}>
                    {review.body}
                  </Typography>
                  <Box
                    sx={{
                      '& svg': {
                        fill: theme.palette.care?.yellow[600],
                      },
                      margin: '10px auto 10px',
                      width: '90px',
                    }}>
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-flex',
                        verticalAlign: 'sub',
                      }}>
                      <RatingStars />
                    </Box>
                  </Box>
                  <Typography variant="caption">{review.author}</Typography>
                </Box>
              ))}
            </Carousel>
          </Box>
        </Box>
      </Box>
      <Reviews />
    </>
  );
};

export default Testimonials;
