import { Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  children: React.ReactNode;
  sectionLabel: string;
}

const FormSection: FC<Props> = ({ children, sectionLabel }) => {
  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Typography textAlign="left" component="legend">
            {sectionLabel}
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default FormSection;
