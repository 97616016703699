import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import getConfig from 'next/config';
import { NextConfig } from '@/interfaces';
import { PAGE_PROPERTIES } from '@/constants';
import Header from '@/components/Layout/Header';
import HomepayLogo from '../../../../assets/images/homepay-logo.svg';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { PARTNERS_URL } = publicRuntimeConfig;

const PartnersHeader: FC = () => {
  const isMobile = useMediaQuery('(max-width: 974px)');

  const homepayLogo = <HomepayLogo data-testid="homepay-partners-logo" />;

  return (
    <Header
      branding={{ logo: homepayLogo, href: `${PARTNERS_URL}/homepay` }}
      isMobile={isMobile}
      leftLinks={[
        {
          text: 'Home',
          href: `${PARTNERS_URL}/homepay`,
          type: 'link',
          isVisible: isMobile,
        },
        {
          text: 'Service & Pricing',
          href: `#service_pricing`,
          type: 'link',
        },
        {
          text: 'Service Guarantee',
          href: `#service_guarantee`,
          type: 'link',
        },
        {
          text: 'Benefits for Employees',
          href: `#benefits_for_employees`,
          type: 'link',
        },
      ]}
      pageProperties={PAGE_PROPERTIES.partners}
    />
  );
};

export default PartnersHeader;
