import { Box, Grid, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import Step from '@/components/pages/shared/Step';
import CustomerService from '../../../../assets/images/customer-service.svg';
import Pay from '../../../../assets/images/pay.svg';
import PencilPaper from '../../../../assets/images/pencil-paper.svg';

const PartnerTaskCards: React.FC = () => {
  return (
    <Box maxWidth="lg">
      <Box mb={3} textAlign="center">
        <Typography variant="serifHeadline2">
          HomePay takes time consuming tasks off your plate
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Step color={theme.palette.care.grey[50]} height={260}>
          <PencilPaper />
          <Box mt={2} mb={1}>
            <Typography variant="serifHeadline4">Taxes</Typography>
          </Box>
          <Typography variant="body1">
            Set up a pay cadence that works for you, and we&apos;ll onboard your employees to save
            you time.
          </Typography>
        </Step>
        <Step color={theme.palette.care.grey[50]} height={260}>
          <Pay />
          <Box mt={2} mb={1}>
            <Typography variant="serifHeadline4">Payroll</Typography>
          </Box>
          <Typography variant="body1">
            We prepare your quarterly and year-end taxes - filed on-time, every time. Guaranteed.
          </Typography>
        </Step>
        <Step color={theme.palette.care.grey[50]} height={265}>
          <CustomerService />
          <Box mt={2} mb={1}>
            <Typography variant="serifHeadline4">HR support</Typography>
          </Box>
          <Typography variant="body1">
            Contact our award-winning tax, payroll & HR specialists to have your questions answered
            quickly and accurately.
          </Typography>
        </Step>
      </Grid>
    </Box>
  );
};

export default PartnerTaskCards;
