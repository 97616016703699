import React from 'react';
import { Box, Grid, List, Typography } from '@mui/material';
import { Modal } from '@care/react-component-lib';
import { theme as careTheme, theme } from '@care/material-ui-theme';
import { Icon24UtilityCheckmarkLarge } from '@care/react-icons';
import { PAGE_PROPERTIES } from '@/constants';
import CTAButton from '../../shared/CTAButton';

const SERVICE_LIST = [
  {
    items: [
      'Prepare, file and remit federal and state taxes',
      'Point of contact for state tax agencies',
      'Manage official notices',
      'Online access to archived tax records',
      'Set up federal and state tax accounts',
      'File required New Hire Report with tax agencies',
      'Optional: Year-end tax preparation ($100)**',
    ],
    serviceTitle: 'Tax processing',
  },
  {
    items: [
      "Calculate employee's withholdings and net pay",
      'Automatic payroll processing on any device',
      'Direct deposit each pay period',
      'Track PTO accruals and other reimbursements',
      'Online access to payroll records and paystubs',
    ],
    serviceTitle: 'Payroll management',
  },
  {
    items: [
      'Unlimited phone, chat and email support',
      'Unlimited support for your employee(s)',
      "Support if you're ever audited",
      'Lifetime access to your payroll and tax records',
      'On-board your employee(s) for you',
    ],
    serviceTitle: 'Concierge support',
  },
];

interface Props {
  defaultPrice?: number;
  isVisible: boolean;
  onClose: () => void;
  title: string;
  partnerName?: string;
  marketingPath?: string;
  isNoBilling: boolean;
}

const ModalServiceList = ({
  defaultPrice,
  isVisible,
  title,
  onClose,
  partnerName,
  marketingPath,
  isNoBilling,
}: Props) => {
  const firstMonthFree = partnerName && defaultPrice === 75;

  const getListBoxes = (itemList: string[]) => {
    return (
      <List>
        {itemList?.map((item: string, index: number) => {
          return (
            <React.Fragment key={`list-${index}`}>
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  padding: theme.spacing(0, 0, 0, 1.25),
                }}>
                <Icon24UtilityCheckmarkLarge
                  sx={{
                    margin: theme.spacing(0.75, 0, 0, 0),
                  }}
                  color={careTheme.palette.care?.red[500]}
                  size={16}
                />
                <Typography
                  color="secondary"
                  variant="body2"
                  sx={{ marginTop: theme.spacing(0.5), marginLeft: theme.spacing(1) }}>
                  {item.includes('Optional') ? (
                    <>
                      <strong>Optional:</strong>
                      {item.replace('Optional:', '')}
                    </>
                  ) : (
                    item
                  )}
                </Typography>
              </Box>
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  const getServiceList = () =>
    SERVICE_LIST?.map(({ items, serviceTitle }, index) => (
      <Grid item data-testid={`service-item-${index}`} key={serviceTitle} md={4}>
        <Typography
          sx={{
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
              marginTop: theme.spacing(2),
            },
          }}
          variant="h2">
          {serviceTitle}
        </Typography>
        {getListBoxes(items)}
        {index === 2 && (
          <Box mt={2} pl={1.25}>
            <Typography color="grey" variant="body2">
              ** Includes Schedule H, Form 1040, Form W-2, Form W-3
            </Typography>
          </Box>
        )}
      </Grid>
    ));

  const price = partnerName === undefined ? '$75' : `$${defaultPrice}`;

  return (
    <Modal
      sx={{
        '& .MuiPaper-root': {
          width: {
            sm: '100%',
          },
          [theme.breakpoints.up('md')]: {
            minWidth: '950px',
          },

          [theme.breakpoints.down('md')]: {
            borderRadius: '24px',
            marginTop: theme.spacing(10),
            paddingBottom: theme.spacing(10),
          },
        },

        '& .MuiTypography-h3': {
          fontFamily: 'Lora, serif',
          fontWeight: 400,
          fontSize: '28px',
        },
      }}
      maxWidth={false}
      onClose={onClose}
      open={isVisible}
      title={title}
      variant="dynamic">
      <Grid container direction="column">
        <Box
          sx={{
            alignItems: 'center',
            alignSelf: 'center',
            backgroundColor: theme.palette.care?.latte[50],
            borderRadius: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            margin: theme.spacing(4, 0, 3, 0),
            padding: theme.spacing(3, 0),
            textAlign: 'center',
            width: '55%',

            [theme.breakpoints.down('lg')]: {
              width: '80%',
            },
          }}>
          <Box alignItems="center" display="flex" flexDirection="row">
            {!isNoBilling ? (
              <>
                <Typography
                  sx={{
                    margin: theme.spacing(0, 0.5, 0, 0),
                  }}
                  variant="display3">
                  {price}
                </Typography>
                <Typography variant="h1" fontWeight="400">
                  /month
                </Typography>
              </>
            ) : (
              <Typography variant="h6">Billed through partner</Typography>
            )}
          </Box>
          {firstMonthFree && <Typography variant="body3">First month free!</Typography>}
          {!isNoBilling && <Typography variant="body3">No setup fee</Typography>}
          <CTAButton
            sx={{
              width: '245px',
            }}
            color="primary"
            pageProperties={PAGE_PROPERTIES.partners}
            variant="contained"
            partnerName={partnerName}
            marketingPath={marketingPath}
          />
        </Box>
        <Grid sx={{ padding: theme.spacing(0, 2) }} container direction="row" spacing={1}>
          {getServiceList()}
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ModalServiceList;
