import { FC, useState } from 'react';
import { theme } from '@care/material-ui-theme';
import { Box, Grid, Button, Typography } from '@mui/material';
import Image from 'next/image';
import { ModalVideo } from '@/components/pages/shared';
import { HOW_HP_WORKS_PARTNERS_URL } from '@/constants';
import familyImage from '../../../../assets/images/partner-simplifies.jpg';
import familyImage2 from '../../../../assets/images/partner-guarantee.jpg';
import familyImage3 from '../../../../assets/images/homepay-helps.jpg';
import Testimonials from './Testimonials';

const Description: FC = () => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const handleModalVisibility = () => {
    setModalVisibility((visible) => !visible);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box textAlign="center">
              <Image src={familyImage} width={489} height={316} alt="family-image" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="serifHeadline2" gutterBottom>
              HomePay simplifies household employment
            </Typography>
            <Typography variant="body1" gutterBottom>
              Household employers have some federal and state obligations that can feel overwhelming
              for busy families. <br /> <br />
              The good news is paying legally is not difficult or as expensive as you think. HomePay
              handles all the compliance details and there are tax breaks that typically offset most
              of the employer taxes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.care.grey[50],
            padding: theme.spacing(2),
            justifyContent: 'space-around',
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
            flexWrap: 'wrap',
            [theme.breakpoints.up('md')]: {
              padding: theme.spacing(4),
            },
          }}>
          <Typography
            variant="body1"
            sx={{
              paddingTop: theme.spacing(1),
            }}>
            Your loved one’s care is your top priority.{' '}
            <strong>Let HomePay take care of the rest.</strong>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                flex: '0 0 auto',
              },
            }}
            onClick={handleModalVisibility}>
            See how
          </Button>
          <ModalVideo
            dataTestId="partners-how-homepay-works-modal"
            isVisible={isModalVisible}
            onClose={handleModalVisibility}
            title="How HomePay works"
            videoUrl={HOW_HP_WORKS_PARTNERS_URL}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        id="service_guarantee"
        sx={{
          scrollMarginTop: '140px',
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box pb={2}>
              <Typography variant="serifHeadline2">Our happiness guarantee</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              If HomePay doesn’t meet your expectations for an all-in-one nanny tax and payroll
              service during your first six months of use, we’ll refund your payments for our
              services.*
            </Typography>
            <Box pt={2} pb={2}>
              <Typography variant="serifHeadline2">Our service guarantee</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              We guarantee that your tax returns are filed accurately and on time.**
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="center">
              <Image src={familyImage2} width={489} height={316} alt="family-image" />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Testimonials />
      </Grid>

      <Grid item xs={12} id="benefits_for_employees" sx={{ scrollMarginTop: '140px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box textAlign="center">
              <Image src={familyImage3} width={489} height={316} alt="family-image" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="serifHeadline2" gutterBottom>
              HomePay helps your nanny too
            </Typography>
            <Typography variant="body1" gutterBottom>
              Household employers have some federal and state obligations that can feel overwhelming
              for busy families. <br />
              <br />
              The good news is paying legally is not difficult or as expensive as you think. HomePay
              handles all the compliance details and there are tax breaks that typically offset most
              of the employer taxes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Description;
